import React from "react";
import "./style.scss";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__text">
        © Copy Desk Creative Services {new Date().getFullYear()}. All Rights
        Reserved
      </div>
      <div className="footer__poweredby">
        Powered by{" "}
        <a href="http://blackelephantdigital.com/">
          <img
            alt="Black Elephant Logo"
            className="footer__poweredby__image"
            src="http://cdcsgroup.com/assets/images/BE(LOGO)footer200x200.png"
          ></img>
        </a>
      </div>
    </footer>
  );
}
