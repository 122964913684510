import React, { useState } from "react";
import "./style.scss";
import axios from "axios";

const initialState = {
  name: "",
  organization: "",
  contact: "",
  email: "",
  query: "",
  attachment: "",
  comment: "",
};
export default function Contact() {
  const [values, setValues] = useState(initialState);
  const [success, setSuccess] = useState(false);

  function handleOnChange(e) {
    if (e.target.name !== "attachment") {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }
  }

  async function onSubmit(e) {
    e.preventDefault();

    const { name, organization, contact, email, query, attachment, comment } =
      values;
    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", contact);
    formData.append("organization", organization);
    formData.append("query", query);
    formData.append("attachment", attachment);
    formData.append("comment", comment);

    try {
      const response = await axios.post(
        "https://cdcsgroup.com/api/public/index.php/contact",
        formData
      );

      setValues(initialState);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);

      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className="contact" id="connect">
      <h2 className="heading">CONTACT</h2>

      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col col-12 col-xl-6">
            <label>Name *</label>
            <input
              name="name"
              value={values.name}
              onChange={handleOnChange}
              required
            ></input>
          </div>
          <div className="col col-12 col-xl-6">
            <label>Organization *</label>
            <input
              name="organization"
              value={values.organization}
              onChange={handleOnChange}
              required
            ></input>
          </div>
          <div className="col col-12 col-xl-6">
            <label>Contact No. *</label>
            <input
              name="contact"
              value={values.contact}
              onChange={handleOnChange}
              required
            ></input>
          </div>
          <div className="col col-12 col-xl-6">
            <label>Email</label>
            <input
              name="email"
              value={values.email}
              onChange={handleOnChange}
              type="email"
            ></input>
          </div>
          <div className="col col-12 col-xl-6">
            <label>Query Type</label>
            <input
              name="query"
              value={values.query}
              onChange={handleOnChange}
            ></input>
          </div>
          <div className="col col-12 col-xl-6">
            <label>Attachment</label>
            <input
              name="attachment"
              // value={values.attachment}
              onChange={handleOnChange}
              type="file"
            ></input>
          </div>
          <label>Comment</label>
          <textarea
            name="comment"
            value={values.comment}
            onChange={handleOnChange}
          ></textarea>

          {success && (
            <div className="success">
              Thank you. Your response has been recorded.
            </div>
          )}

          <button>Submit</button>
        </div>
      </form>
    </div>
  );
}
