const FM1 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM1.png";
const FM2 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM2.png";
const FM3 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM3.png";
const FM4 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM4.png";
const FM5 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM5.png";
const FM6 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM6.png";
const FM7 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM7.png";
const FM8 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM8.png";
const FM9 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM9.png";
const FM10 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM10.png";
const FM11 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM11.png";
const FM12 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM12.png";
const FM13 = "http://cdcsgroup.com/assets/images/partners/MEDIA/FM/FM13.png";
const NEWS1 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS1.png";
const NEWS2 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS2.png";
const NEWS3 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS3.png";
const NEWS4 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS4.png";
const NEWS5 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS5.png";
const NEWS6 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS6.png";
const NEWS7 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS7.png";
const NEWS8 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS8.png";
const NEWS9 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS9.png";
const NEWS10 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS10.png";
const NEWS11 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS11.png";
const NEWS12 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS12.png";
const NEWS13 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS13.png";
const NEWS14 =
  "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS14.png";
// const NEWS15 = "http://cdcsgroup.com/assets/images/partners/MEDIA/NEWSPAPER/NEWS15.png"
const FS1 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS1.png";
const FS2 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS2.png";
const FS3 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS3.png";
const FS4 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS4.png";
const FS5 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS5.png";
const FS6 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS6.png";
const FS7 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS7.png";
const FS8 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS8.png";
const FS9 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS9.png";
const FS10 =
  "http://cdcsgroup.com/assets/images/partners/FINANCIAL SERVICES/FS10.png";
const M1 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/M1.png";
const M2 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/M2.png";
const TV1 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV1.png";
const TV2 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV2.png";
const TV3 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV3.png";
const TV4 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV4.png";
const TV5 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV5.png";
const TV6 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV6.png";
const TV7 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV7.png";
const TV8 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV8.png";
const TV9 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV9.png";
const TV10 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV10.png";
const TV11 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV11.png";
const TV12 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV12.png";
const TV13 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV13.png";
const TV14 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV14.png";
const TV15 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV15.png";
const TV16 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV16.png";
const TV17 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV17.png";
const TV18 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV18.png";
const TV19 = "http://cdcsgroup.com/assets/images/partners/MEDIA/TV/TV19.png";
const AUTO1 =
  "http://cdcsgroup.com/assets/images/partners/AUTOMOBILE/AUTO1.png";
const AUTO2 =
  "http://cdcsgroup.com/assets/images/partners/AUTOMOBILE/AUTO2.png";
const HC1 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC1.png";
const HC2 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC2.png";
const HC3 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC3.png";
const HC4 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC4.png";
const HC5 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC5.png";
const HC6 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC6.png";
const HC7 = "http://cdcsgroup.com/assets/images/partners/HEALTH CARE/HC7.png";
const CD1 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD1.png";
const CD2 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD2.png";
const CD3 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD3.png";
const CD4 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD4.png";
const CD5 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD5.png";
const CD6 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD6.png";
const CD7 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD7.png";
const CD8 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD8.png";
const CD9 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD9.png";
const CD10 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD10.png";
const CD11 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD11.png";
const CD12 =
  "http://cdcsgroup.com/assets/images/partners/CONSUMER DURABLES/CD12.png";
const ED1 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED1.png";
const ED2 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED2.png";
const ED3 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED3.png";
const ED4 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED4.png";
const ED5 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED5.png";
const ED6 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED6.png";
const ED7 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED7.png";
const ED8 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED8.png";
const ED9 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED9.png";
const ED10 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED10.png";
const ED11 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED11.png";
const ED12 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED12.png";
const ED13 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED13.png";
const ED14 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED14.png";
const ED15 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED15.png";
const ED16 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED16.png";
const ED17 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED17.png";
const ED18 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED18.png";
const ED19 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED19.png";
const ED20 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED20.png";
const ED21 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED21.png";
const ED22 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED22.png";
const ED23 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED23.png";
const ED24 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED24.png";
const ED25 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED25.png";
const ED26 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED26.png";
const ED27 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED27.png";
const ED28 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED28.png";
const ED29 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED29.png";
const ED30 = "http://cdcsgroup.com/assets/images/partners/EDUCATION/ED30.png";
const FMCG1 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG1.png";
const FMCG2 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG2.png";
const FMCG3 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG3.png";
const FMCG4 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG4.png";
const FMCG5 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG5.png";
const FMCG6 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG6.png";
const FMCG7 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG7.png";
const FMCG8 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG8.png";
const FMCG9 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG9.png";
const FMCG10 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG10.png";
const FMCG11 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG11.png";
const FMCG12 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG12.png";
const FMCG13 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG13.png";
const FMCG14 = "http://cdcsgroup.com/assets/images/partners/FMCG/FMCG14.png";
const GOVERNMENT1 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT1.png";
const GOVERNMENT2 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT2.png";
const GOVERNMENT3 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT3.png";
const GOVERNMENT4 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT4.png";
const GOVERNMENT5 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT5.png";
const GOVERNMENT6 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT6.png";
const GOVERNMENT7 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT7.png";
const GOVERNMENT8 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT8.png";
const GOVERNMENT9 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT9.png";
const GOVERNMENT10 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT10.png";
const GOVERNMENT11 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT11.png";
const GOVERNMENT12 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT12.png";
const GOVERNMENT13 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT13.png";
const GOVERNMENT14 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT14.png";
const GOVERNMENT15 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT15.png";
const GOVERNMENT16 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT16.png";
const GOVERNMENT17 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT17.png";
const GOVERNMENT18 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT18.png";
const GOVERNMENT19 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT19.png";
const GOVERNMENT20 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT20.png";
const GOVERNMENT21 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT21.png";
const GOVERNMENT22 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT22.png";
const GOVERNMENT23 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT23.png";
const GOVERNMENT24 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT24.png";
const GOVERNMENT25 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT25.png";
const GOVERNMENT26 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT26.png";
const GOVERNMENT27 =
  "http://cdcsgroup.com/assets/images/partners/GOVERNMENT/GOVT27.png";
const OT1 = "http://cdcsgroup.com/assets/images/partners/other/ot1.png";
const OT2 = "http://cdcsgroup.com/assets/images/partners/other/ot2.png";
const OT3 = "http://cdcsgroup.com/assets/images/partners/other/ot3.png";
const OT4 = "http://cdcsgroup.com/assets/images/partners/other/ot4.png";
const OT5 = "http://cdcsgroup.com/assets/images/partners/other/ot5.png";
const OT6 = "http://cdcsgroup.com/assets/images/partners/other/ot6.png";
const OT7 = "http://cdcsgroup.com/assets/images/partners/other/ot7.png";
const OT8 = "http://cdcsgroup.com/assets/images/partners/other/ot8.png";
const OT9 = "http://cdcsgroup.com/assets/images/partners/other/ot9.png";
const OT10 = "http://cdcsgroup.com/assets/images/partners/other/ot10.png";
const OT11 = "http://cdcsgroup.com/assets/images/partners/other/ot11.png";
const OT12 = "http://cdcsgroup.com/assets/images/partners/other/ot12.png";
const OT13 = "http://cdcsgroup.com/assets/images/partners/other/ot13.png";
const OT14 = "http://cdcsgroup.com/assets/images/partners/other/ot14.png";
const OT15 = "http://cdcsgroup.com/assets/images/partners/other/ot15.png";
const OT16 = "http://cdcsgroup.com/assets/images/partners/other/ot16.png";
const OT17 = "http://cdcsgroup.com/assets/images/partners/other/ot17.png";

export const media = [
  {
    logoUrl: NEWS1,
    name: "Business India",
  },
  {
    logoUrl: NEWS2,
    name: "Business Standard",
  },
  {
    logoUrl: NEWS3,
    name: "Global Times",
  },
  {
    logoUrl: NEWS4,
    name: "Hindustan Times",
  },
  {
    logoUrl: NEWS5,
    name: "The Huffington Post",
  },
  {
    logoUrl: NEWS6,
    name: "Live Mint",
  },
  {
    logoUrl: NEWS7,
    name: "Loksatta",
  },
  {
    logoUrl: NEWS8,
    name: "Sahara",
  },
  {
    logoUrl: NEWS9,
    name: "The Asian Age",
  },
  {
    logoUrl: NEWS10,
    name: "The Hindu",
  },
  {
    logoUrl: NEWS11,
    name: "The Indian Express",
  },
  {
    logoUrl: NEWS12,
    name: "The Times Of India",
  },
  {
    logoUrl: NEWS13,
    name: "Eastern Eye",
  },
  {
    logoUrl: NEWS14,
    name: "Annapurna",
  },
  // {
  // 	logoUrl:NEWS15
  // }

  {
    logoUrl: FM1,
    name: "91.1 FM - Radio City",
  },
  {
    logoUrl: FM2,
    name: "92.7 Big FM",
  },
  {
    logoUrl: FM3,
    name: "94.3 My FM",
  },
  {
    logoUrl: FM4,
    name: "94.3 Radio One",
  },
  {
    logoUrl: FM5,
    name: "Aakashwani",
  },
  {
    logoUrl: FM6,
    name: "BBC Radio",
  },
  {
    logoUrl: FM7,
    name: "Fever 104 FM",
  },
  {
    logoUrl: FM8,
    name: "Hello 106.4 FM",
  },
  {
    logoUrl: FM9,
    name: "Hit FM 95",
  },
  {
    logoUrl: FM10,
    name: "Radio Indigo 91.9 FM",
  },
  {
    logoUrl: FM11,
    name: "Radio Mirchi",
  },
  {
    logoUrl: FM12,
    name: "Red FM 93.5",
  },
  {
    logoUrl: FM13,
    name: "Dhamaal 106.4 FM",
  },

  {
    logoUrl: TV1,
    name: "Aljazeera",
  },
  {
    logoUrl: TV2,
    name: "BBC",
  },
  {
    logoUrl: TV3,
    name: "BBC London",
  },
  {
    logoUrl: TV4,
    name: "CNBC TV18",
  },
  {
    logoUrl: TV5,
    name: "CNN News 18",
  },
  {
    logoUrl: TV6,
    name: "Desimartini.com",
  },
  {
    logoUrl: TV7,
    name: "HT Media",
  },
  {
    logoUrl: TV8,
    name: "Hungama",
  },
  {
    logoUrl: TV9,
    name: "India Today",
  },
  {
    logoUrl: TV10,
    name: "India TV",
  },
  {
    logoUrl: TV11,
    name: "Karvy",
  },
  {
    logoUrl: TV12,
    name: "NDTV",
  },
  {
    logoUrl: TV13,
    name: "NewsX",
  },
  {
    logoUrl: TV14,
    name: "Rishtey",
  },
  {
    logoUrl: TV15,
    name: "Starone",
  },
  {
    logoUrl: TV16,
    name: "T-Series",
  },
  {
    logoUrl: TV17,
    name: "Times-Now",
  },
  {
    logoUrl: TV18,
    name: "UTV",
  },
  {
    logoUrl: TV19,
    name: "Videocon",
  },
  {
    logoUrl: M1,
    name: "ABC Communications",
  },
  {
    logoUrl: M2,
    name: "Dimension Consulting",
  },
];

export const automobile = [
  {
    logoUrl: AUTO1,
  },
  {
    logoUrl: AUTO2,
  },
];
export const healthCare = [
  {
    logoUrl: HC1,
    name: "Max Healthcare",
  },
  {
    logoUrl: HC2,
    name: "Sharmayu",
  },
  {
    logoUrl: HC3,
    name: "Baidyanath",
  },
  {
    logoUrl: HC4,
    name: "Eleganza",
  },
  {
    logoUrl: HC5,
    name: "Alp's Cosmetic Clinic",
  },
  {
    logoUrl: HC6,
    name: "Kottakkal Ayurveda",
  },
  {
    logoUrl: HC7,
    name: "Cleanzo",
  },
];
export const consumerDurables = [
  {
    logoUrl: CD1,
    name: "Bosch",
  },
  {
    logoUrl: CD2,
    name: "HCL",
  },
  {
    logoUrl: CD3,
    name: "HP",
  },
  {
    logoUrl: CD4,
    name: "Infosys",
  },
  {
    logoUrl: CD5,
    name: "Lenovo",
  },
  {
    logoUrl: CD6,
    name: "LG",
  },
  {
    logoUrl: CD7,
    name: "Moserbaer",
  },
  {
    logoUrl: CD8,
    name: "Xerox",
  },
  {
    logoUrl: CD9,
    name: "Delhi Scooters",
  },
  {
    logoUrl: CD10,
    name: "Exide",
  },
  {
    logoUrl: CD11,
    name: "Su-Kam",
  },
  {
    logoUrl: CD12,
    name: "Surya",
  },
];
export const education = [
  {
    logoUrl: ED1,
    name: "Bal Bharti School",
  },
  {
    logoUrl: ED2,
    name: "Chanakya Center for Strategic Studies",
  },
  {
    logoUrl: ED3,
    name: "Chankya Center for Strategic Studies",
  },
  {
    logoUrl: ED4,
    name: "Delhi Public School",
  },
  {
    logoUrl: ED5,
    name: "htcampus.com",
  },
  {
    logoUrl: ED6,
    name: "IEC School of Art & fashion",
  },
  {
    logoUrl: ED7,
    name: "International Public School",
  },
  {
    logoUrl: ED8,
    name: "K.R. Mangalam University",
  },
  {
    logoUrl: ED9,
    name: "K.R. Mangalam University",
  },
  {
    logoUrl: ED10,
    name: "K.R. Mangalam World School",
  },
  {
    logoUrl: ED11,
    name: "K.R. Mangalam World School",
  },
  {
    logoUrl: ED12,
    name: "Lotus Valley International School",
  },
  {
    logoUrl: ED13,
    name: "New Era Institute of Professional Studies",
  },
  {
    logoUrl: ED14,
    name: "NIIT",
  },
  {
    logoUrl: ED15,
    name: "NIPS",
  },
  {
    logoUrl: ED16,
    name: "Rabindranath World School",
  },
  {
    logoUrl: ED17,
    name: "Study Mate",
  },
  {
    logoUrl: ED18,
    name: "Summer Fields School",
  },
  {
    logoUrl: ED19,

    name: "Summer Fields School",
  },
  {
    logoUrl: ED20,
    name: "K.R. Mangalam University",
  },
  {
    logoUrl: ED21,
    name: "Tamana",
  },
  {
    logoUrl: ED22,
    name: "Deshbandhu College",
  },
  {
    logoUrl: ED23,
    name: "G.D. Goenka Global School",
  },
  {
    logoUrl: ED24,
    name: "Indian Institute of Astrophysics",
  },
  {
    logoUrl: ED25,
    name: "New Era Public School",
  },
  {
    logoUrl: ED26,
    name: "R.N.S World School",
  },
  {
    logoUrl: ED27,
    name: "Tagore International School",
  },
  {
    logoUrl: ED28,
    name: "University of Delhi",
  },
  {
    logoUrl: ED29,
    name: "University of London",
  },
  {
    logoUrl: ED30,
    name: "ET&T",
  },
];
export const fmcg = [
  {
    logoUrl: FMCG1,
    name: "Bawa Masala Company",
  },
  {
    logoUrl: FMCG2,
    name: "Coca Cola",
  },
  {
    logoUrl: FMCG3,
    name: "Dabur",
  },
  {
    logoUrl: FMCG4,
    name: "Bawa Industries",
  },
  {
    logoUrl: FMCG5,
    name: "Nestle",
  },
  {
    logoUrl: FMCG6,
    name: "Farm Fresh",
  },
  {
    logoUrl: FMCG7,
    name: "Pan Brand",
  },
  {
    logoUrl: FMCG8,
    name: "Pepsi",
  },
  {
    logoUrl: FMCG9,
    name: "Perfetti",
  },
  {
    logoUrl: FMCG10,
    name: "Savour",
  },
  {
    logoUrl: FMCG11,
    name: "Night Queen",
  },
  {
    logoUrl: FMCG12,
    name: "DFM Foods LTD.",
  },
  {
    logoUrl: FMCG13,
    name: "Not Just Paranthas",
  },
  {
    logoUrl: FMCG14,
    name: "Tagore Indian",
  },
];
export const government = [
  {
    logoUrl: GOVERNMENT1,
    name: "BHEL",
  },
  {
    logoUrl: GOVERNMENT2,
    name: "Delhi Police",
  },
  {
    logoUrl: GOVERNMENT3,
    name: "ESIC",
  },
  {
    logoUrl: GOVERNMENT4,
    name: "Incredible Tribal India",
  },
  {
    logoUrl: GOVERNMENT5,
    name: "MTNL",
  },
  {
    logoUrl: GOVERNMENT6,
    name: "NTPC",
  },
  {
    logoUrl: GOVERNMENT7,
    name: "ONGC",
  },
  {
    logoUrl: GOVERNMENT8,
    name: "SAIL",
  },
  {
    logoUrl: GOVERNMENT9,
    name: "Tribes India",
  },
  {
    logoUrl: GOVERNMENT10,
    name: "Delhi Govenment",
  },
  {
    logoUrl: GOVERNMENT11,
    name: "Election Commission of India",
  },
  {
    logoUrl: GOVERNMENT12,
    name: "Employess Provident Fund Organization",
  },
  {
    logoUrl: GOVERNMENT13,
    name: "Chhattisgarh Shashan",
  },
  {
    logoUrl: GOVERNMENT14,
    name: "Department of Agriculture",
  },
  {
    logoUrl: GOVERNMENT15,
    name: "Ministry of Driking Water and Sanitization",
  },
  {
    logoUrl: GOVERNMENT16,
    name: "Ministry of Health and Family Welfare",
  },
  {
    logoUrl: GOVERNMENT17,
    name: "Ministry of Panchayati Raj",
  },
  {
    logoUrl: GOVERNMENT18,
    name: "Ministry of Power",
  },
  {
    logoUrl: GOVERNMENT19,
    name: "Indian Railways",
  },
  {
    logoUrl: GOVERNMENT20,
    name: "Department of Science and Technology",
  },
  {
    logoUrl: GOVERNMENT21,
    name: "Ministry of Steel",
  },
  {
    logoUrl: GOVERNMENT22,
    name: "Ministry of Tribal Affairs",
  },
  {
    logoUrl: GOVERNMENT23,
    name: "Ministry of Women and Child Development",
  },
  {
    logoUrl: GOVERNMENT24,
    name: "CSIR India",
  },
  {
    logoUrl: GOVERNMENT25,
    name: "EESL",
  },
  {
    logoUrl: GOVERNMENT26,
    name: "Bharatiya Khhad Nigam",
  },
  {
    logoUrl: GOVERNMENT27,
    name: "WWF",
  },
];
export const financialServices = [
  {
    logoUrl: FS1,
  },
  {
    logoUrl: FS2,
  },
  {
    logoUrl: FS3,
  },
  {
    logoUrl: FS4,
  },
  {
    logoUrl: FS5,
  },
  {
    logoUrl: FS6,
  },
  {
    logoUrl: FS7,
  },
  {
    logoUrl: FS8,
  },
  {
    logoUrl: FS9,
  },
  {
    logoUrl: FS10,
  },
];

export const other = [
  {
    logoUrl: OT1,
    name: "ABN-AMRO",
  },
  {
    logoUrl: OT2,
    name: "Bajaj Capital",
  },
  {
    logoUrl: OT3,
    name: "Bata",
  },
  {
    logoUrl: OT4,
    name: "Eastwind Captial Advisors",
  },
  {
    logoUrl: OT5,
    name: "Fidelity",
  },
  {
    logoUrl: OT6,
    name: "Givo",
  },
  {
    logoUrl: OT7,
    name: "ICICI Bank",
  },
  {
    logoUrl: OT8,
    name: "ING",
  },
  {
    logoUrl: OT9,
    name: "JM Financial",
  },
  {
    logoUrl: OT10,
    name: "Kotak",
  },
  {
    logoUrl: OT11,
    name: "Standard Chartered",
  },
  {
    logoUrl: OT12,
    name: "Bank of America",
  },
  {
    logoUrl: OT13,
    name: "Hyundai",
  },
  {
    logoUrl: OT14,
    name: "Maruti Suzuki",
  },
  {
    logoUrl: OT15,
    name: "Aravalli Group",
  },
  // {
  //   logoUrl: OT16,
  // },
  {
    logoUrl: OT17,
    name: "The Body Shop",
  },
];
