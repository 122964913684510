import * as React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Placeholder from "../components/placeholder";
import About from "../components/about";
import Partners from "../components/partners";
import GroupInitiatives from "../components/group-initiatives";
import People from "../components/people";
import Contact from "../components/contact";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home | CDCS Group"
        description="Copy Desk Creative services, estd. 1992, is an INS and Doordarshan accredited media management firm, specialising in print and electronic media releases."
      />
      <main>
        <Hero />
        <About />
        <Partners />
        <GroupInitiatives />
        <People />
        <Contact />
      </main>
    </Layout>
  );
};

export default IndexPage;
