import React from "react";
import "./style.scss";

import pic1 from "../../images/team/hbalagopal.png";
import pic2 from "../../images/team/nityabalagopal.png";
import pic3 from "../../images/team/monikabehgel.png";
const peopleData = [
  {
    name: "H. Balagopal",
    image: pic1,
    designation: "Founder and Executive Director",
  },
  {
    name: "Nitya Balagopal",
    image: pic2,
    designation: "Associate Director, Marketing Strategy",
  },
  // {
  //   name: "Monika Baghel",
  //   image: pic3,
  //   designation: "Accounts Manager",
  // },
];

export default function People() {
  return (
    <div id="people" className="people">
      <h2 className="heading">People</h2>
      <div className="people__container">
        <div className="row">
          {peopleData.map((item, i) => (
            <div className="col col-12 col-md-6">
              <div className="people__card">
                <div className="people__card__header">
                  <img src={item.image}></img>
                </div>
                <div className="people__card__body">
                  <div className="people__card__body__name">{item.name}</div>
                  <div className="people__card__body__designation">
                    {item.designation}
                  </div>
                  {/* <div>{item.name}</div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
