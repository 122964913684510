import React from "react";
import "./style.scss";
export default function Hero() {
  return (
    <div className="hero">
      {/* <h1 className="hero__heading">
        29 Years of Excellence
        <br />
        in Media Management
      </h1> */}

      <img
        className="hero__desktop"
        src="https://cdcsgroup.com/assets/images/CDCS_Hero_banner.jpg"
      ></img>
      <img
        className="hero__mobile"
        src="https://cdcsgroup.com/assets/images/CDCS_Hero_banner_mobile.jpg"
      ></img>
    </div>
  );
}
