import React, { useState } from "react";
import "./style.scss";

import {
  media,
  government,
  education,
  fmcg,
  consumerDurables,
  healthCare,
  other,
} from "./data";
export default function Partners() {
  const [tabIndex, setTabIndex] = useState(0);

  let displayData = [];

  switch (tabIndex) {
    case 0:
      displayData = media;
      break;
    case 1:
      displayData = government;
      break;
    case 2:
      displayData = education;
      break;
    case 3:
      displayData = fmcg;
      break;
    case 4:
      displayData = consumerDurables;
      break;
    case 5:
      displayData = healthCare;
      break;
    case 6:
      displayData = other;
      break;
  }

  displayData = displayData.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div id="partners" className="partners">
      <h2 className="heading">Partners</h2>
      <div className="partners__tabs">
        <div
          className={`partners__tabs__tab ${
            tabIndex === 0 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(0);
          }}
        >
          Media
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 1 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(1);
          }}
        >
          Government
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 2 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(2);
          }}
        >
          Education
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 3 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(3);
          }}
        >
          FMCG
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 4 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(4);
          }}
        >
          Consumer Durables
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 5 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(5);
          }}
        >
          Pharmaceutical & Healthcare
        </div>
        <div
          className={`partners__tabs__tab ${
            tabIndex === 6 && "partners__tabs__tab--active"
          }`}
          onClick={() => {
            setTabIndex(6);
          }}
        >
          Other
        </div>
      </div>
      <div className="partners__logos">
        {displayData.map((item, i) => (
          <img
            key={`partners__logo__${i}`}
            className="partners__logos__logo"
            src={item.logoUrl}
          ></img>
        ))}
      </div>
    </div>
  );
}
