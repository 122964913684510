import React, { useState } from "react";
import "./style.scss";
export default function Navbar() {
  const [active, setActive] = useState(false);
  const links = [
    {
      url: "/#partners",
      text: "Partners",
      dropdown: [],
    },

    {
      url: "/#group-initiatives",
      text: "Group Initiatives",
      dropdown: [],
    },
    {
      url: "/#people",
      text: "People",
      dropdown: [],
    },

    {
      url: "#",
      text: "Portfolio",
      dropdown: [
        {
          url: "/portfolio/print-media/",
          text: "Print & Media",
        },
        {
          url: "/portfolio/design-desk/",
          text: "Design Desk",
        },
        {
          url: "/portfolio/electronic-digital/",
          text: "Electronic & Digital",
        },
        {
          url: "/portfolio/events-exhibition/",
          text: "Events & Exhibition",
        },
      ],
    },
    {
      url: "/#connect",
      text: "Connect",
      dropdown: [],
    },
  ];
  const navbarDesktop = (
    <nav className="navbar desktop noselect">
      <div className="navbar__logo">
        <a href="/">
          <img
            alt="CDCS Logo"
            src="http://cdcsgroup.com/assets/images/CDCS-476X500.png"
          ></img>
        </a>
      </div>

      <div className="navbar__links">
        {links.map((item, i) => (
          <a href={item.url} className="navbar__links__link">
            {item.text}

            {item.dropdown.length > 0 && (
              <div className="navbar__links__link__dropdown">
                {item.dropdown.map((item) => (
                  <a
                    className="navbar__links__link__dropdown__link"
                    href={item.url}
                  >
                    {item.text}
                  </a>
                ))}
              </div>
            )}
          </a>
        ))}
      </div>

      <div className="navbar__social">
        <a target="_blank" href="https://www.facebook.com/cdcsgroup/">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/cdcs-group/">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a target="_blank" href="https://twitter.com/CDCSGroup">
          <i class="fab fa-twitter"></i>
        </a>
        <a target="_blank" href="mailto:info@cdcsgroup.com">
          <i class="fas fa-envelope"></i>
        </a>
      </div>
    </nav>
  );

  const navbarMobile = (
    <nav className="navbar mobile navbar__mobile noselect">
      <div className="navbar__logo">
        <a href="/">
          <img
            alt="CDCS Logo"
            src="http://cdcsgroup.com/assets/images/CDCS-476X500.png"
          ></img>
        </a>
      </div>

      <button
        className="navbar__mobile__hamburger"
        onClick={() => setActive(!active)}
      >
        <i className="fas fa-bars"></i>
      </button>

      <div className={`navbar__links ${active && "navbar__links--active"}`}>
        <button
          className="navbar__mobile__close"
          onClick={() => setActive(!active)}
        >
          <i class="fas fa-times"></i>
        </button>

        {links.map((item, i) => (
          <a
            href={item.url}
            onClick={() => setActive(!active)}
            className="navbar__links__link"
          >
            {item.text}

            {item.dropdown.length > 0 && (
              <div className="navbar__links__link__dropdown">
                {item.dropdown.map((item) => (
                  <a
                    className="navbar__links__link__dropdown__link"
                    onClick={() => setActive(!active)}
                    href={item.url}
                  >
                    {item.text}
                  </a>
                ))}
              </div>
            )}
          </a>
        ))}

        <div className="navbar__social">
          <a target="_blank" href="https://www.facebook.com/cdcsgroup/">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/cdcs-group/"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a target="_blank" href="https://twitter.com/CDCSGroup">
            <i class="fab fa-twitter"></i>
          </a>
          <a target="_blank" href="mailto:info@cdcsgroup.com">
            <i class="fas fa-envelope"></i>
          </a>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      {navbarDesktop}
      {navbarMobile}
    </>
  );
}
