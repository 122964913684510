import React from "react";
import "./style.scss";

export default function About() {
  return (
    <div className="about">
      <h2 className="heading">Making of the Group</h2>
      <p className="about__text">
        Copy Desk Creative Services (CDCS), was launched as a creative and
        strategic writing service provider for Creative Agencies in 1992, a
        unique startup well before the birth of startup culture.
        <br />
        <br />
        Over three decades later, the small start-up has evolved into a
        multi-crore group with a number of verticals.
        <br />
        <br />
        CDCS Group today comprises of several initiatives focussing on Marketing
        and Education: a comprehensive INS accredited media management agency
        (Copy Desk Creative Services), a full service digital marketing firm
        (Black Elephant Digital), an award-winning visual communications firm
        focussing on tableaux, events and expositions (Balagopal & Bibhuti), a
        firm specializing as marketing service provider to educational
        institutions (EduGate), an Educational Trust commissioning school
        initiatives (Hariharan Educational Foundation), our very own publishing
        house (Ailum), and a monthly magazine for students and their parents
        (School LIVE).
      </p>
    </div>
  );
}
