import React from "react";
import Navbar from "../navbar";
import Footer from "../footer";
import "bootstrap/dist/css/bootstrap-grid.min.css";
export default function Layout({ children }) {
  return (
    <div className="layout">
      <Navbar />
      {children}

      <Footer />
    </div>
  );
}
